export const portfolio1 = [
  {
    id: 1,
    class: "accessories women",
    image: "./images/portfolio/item-1.jpg",
    category: "Accessories",
  },
  {
    id: 2,
    class: "men",
    image: "./images/portfolio/item-2.jpg",
    category: "Men",
  },
  {
    id: 3,
    class: "women accessories",
    image: "./images/portfolio/item-3.jpg",
    category: "Women",
  },
  {
    id: 4,
    class: "men",
    image: "./images/portfolio/item-4.jpg",
    category: "Accessories",
  },
  {
    id: 5,
    class: "men women",
    image: "./images/portfolio/item-5.jpg",
    category: "Women",
  },
  {
    id: 6,
    class: "men accessories",
    image: "./images/portfolio/item-6.jpg",
    category: "Men",
  },
];

export const portfolio2 = [
  {
    class: "accessories women",
    image: "./images/portfolio/4cols/item-1.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men",
    image: "./images/portfolio/4cols/item-2.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/4cols/item-3.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men",
    image: "./images/portfolio/4cols/item-4.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men women",
    image: "./images/portfolio/4cols/item-5.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/4cols/item-6.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "men",
    image: "./images/portfolio/4cols/item-7.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/4cols/item-8.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
];

export const portfolio3 = [
  {
    class: "accessories women",
    image: "./images/portfolio/masonry-3cols/item-1.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men",
    image: "./images/portfolio/masonry-3cols/item-2.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/masonry-3cols/item-3.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men",
    image: "./images/portfolio/masonry-3cols/item-4.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men women",
    image: "./images/portfolio/masonry-3cols/item-5.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/masonry-3cols/item-6.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
];

export const portfolio4 = [
  {
    class: "accessories women",
    image: "./images/portfolio/masonry-4cols/item-1.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men",
    image: "./images/portfolio/masonry-4cols/item-2.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/masonry-4cols/item-3.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men",
    image: "./images/portfolio/masonry-4cols/item-4.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "men women",
    image: "./images/portfolio/masonry-4cols/item-5.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/masonry-4cols/item-6.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "men",
    image: "./images/portfolio/masonry-4cols/item-7.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Men",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/masonry-4cols/item-8.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
];

export const portfolio5 = [
  {
    class: "accessories women",
    image: "./images/portfolio/fullwidth/item-1.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Accessories",
  },
  {
    class: "accessories",
    image: "./images/portfolio/fullwidth/item-2.jpg",
    title: "Nunc dignissim risus",
    category: "Accessories",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/fullwidth/item-3.jpg",
    title: "Cras ornare tristique",
    category: "Men",
  },
  {
    class: "men",
    image: "./images/portfolio/fullwidth/item-4.jpg",
    title: "Vivamus vestibulum ntulla",
    category: "Men",
  },
  {
    class: "men women",
    image: "./images/portfolio/fullwidth/item-5.jpg",
    title: "Vestibulum auctor dapibus",
    category: "Women",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/fullwidth/item-6.jpg",
    title: "Nunc dignissim risus",
    category: "Accessories",
  },
  {
    class: "women accessories",
    image: "./images/portfolio/fullwidth/item-7.jpg",
    title: "Cras ornare tristique",
    category: "Women",
  },
  {
    class: "men accessories",
    image: "./images/portfolio/fullwidth/item-8.jpg",
    title: "Vivamus vestibulum ntulla",
    category: "Accessories",
  },
];

export const shopData = {
  sizes: [
    {
      size: "Extra Small",
      slug: "XS",
    },
    {
      size: "Small",
      slug: "S",
    },
    {
      size: "Medium",
      slug: "M",
    },
    {
      size: "Large",
      slug: "L",
    },
    {
      size: "Extra Large",
      slug: "XL",
    },
  ],

  colors: [
    {
      color: "#cc9966",
      color_name: "Brown",
    },
    {
      color: "#3399cc",
      color_name: "Blue",
    },
    {
      color: "#9966cc",
      color_name: "Purple",
    },
    {
      color: "#333333",
      color_name: "Black",
    },
    {
      color: "#669933",
      color_name: "Green",
    },
    {
      color: "#cc3333",
      color_name: "Red",
    },
    {
      color: "#999999",
      color_name: "Grey",
    },
    {
      color: "#c0c0c0",
      color_name: "Silver",
    },
    {
      color: "#EE7D21",
      color_name: "Orange",
    },
    {
      color: "#FEDC32",
      color_name: "Yellow",
    },
  ],

  brands: [
    {
      brand: "Next",
      slug: "next",
    },
    {
      brand: "River Island",
      slug: "river-island",
    },
    {
      brand: "Geox",
      slug: "geox",
    },
    {
      brand: "New Balance",
      slug: "nex-balance",
    },
    {
      brand: "UGG",
      slug: "ugg",
    },
    {
      brand: "F&F",
      slug: "f-and-f",
    },
    {
      brand: "Nike",
      slug: "nike",
    },
  ],

  categories: [
    {
      name: "Furniture",
      slug: "furniture",
      count: 8,
    },
    {
      name: "Coffee & Tables",
      slug: "coffee-and-tables",
      count: 1,
    },
    {
      name: "Lighting",
      slug: "lighting",
      count: 3,
    },
    {
      name: "Decoration",
      slug: "decoration",
      count: 5,
    },
    {
      name: "Electronics",
      slug: "electronics",
      count: 1,
    },
    {
      name: "Beds",
      slug: "beds",
      count: 2,
    },
    {
      name: "Armchairs & Chaises",
      slug: "armchairs-and-chaises",
      count: 3,
    },
    {
      name: "Sofas & Sleeper Sofas",
      slug: "sofas-and-sleeper-sofas",
      count: 1,
    },
  ],

  prices: [
    {
      min: "0",
      max: "25",
      name: "Under $25",
    },
    {
      min: "25",
      max: "50",
      name: "$25 to $50",
    },
    {
      min: "50",
      max: "100",
      name: "$50 to $100",
    },
    {
      min: "100",
      max: "200",
      name: "$100 to $200",
    },
    {
      min: "200",
      max: null,
      name: "$200 & Above",
    },
  ],
};

export const homeData = {
  brands: [
    {
      name: "brand",
      image: "./images/brands/1.png",
      width: 100,
      height: 23,
    },
    {
      name: "brand",
      image: "./images/brands/2.png",
      width: 101,
      height: 34,
    },
    {
      name: "brand",
      image: "./images/brands/3.png",
      width: 100,
      height: 30,
    },
    {
      name: "brand",
      image: "./images/brands/4.png",
      width: 101,
      height: 39,
    },
    {
      name: "brand",
      image: "./images/brands/5.png",
      width: 100,
      height: 48,
    },
    {
      name: "brand",
      image: "./images/brands/6.png",
      width: 100,
      height: 23,
    },
    {
      name: "brand",
      image: "./images/brands/7.png",
      width: 63,
      height: 32,
    },
    {
      name: "brand",
      image: "./images/brands/8.png",
      width: 80,
      height: 72,
    },
    {
      name: "brand",
      image: "./images/brands/9.png",
      width: 100,
      height: 25,
    },
  ],
  products: [
    // {
    //   id: 273,
    //   name: "DEWALT DCB205 20V",
    //   slug: "dewalt-dcb205-20v",
    //   short_desc:
    //     "Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, ",
    //   price: 69.94,
    //   sum: 200,
    //   sale_price: 20,
    //   review: 2,
    //   ratings: 3,
    //   until: null,
    //   stock: 100,
    //   top: null,
    //   featured: null,
    //   new: true,
    //   author: null,
    //   sold: null,
    //   category: [
    //     {
    //       name: "Accessories",
    //       slug: "accessories",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "18",
    //       },
    //     },
    //     {
    //       name: "Power Tools",
    //       slug: "power-tools",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "74",
    //       },
    //     },
    //   ],
    //   brands: [
    //     {
    //       name: "Nike",
    //       slug: "nike",
    //       pivot: {
    //         product_id: "273",
    //         brand_id: "19",
    //       },
    //     },
    //   ],
    //   pictures: [
    //     {
    //       width: "800",
    //       height: "800",
    //       url: "./images/home/static-products/product1.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1415",
    //       },
    //     },
    //   ],
    //   sm_pictures: [
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product1.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product1.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product1.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //   ],
    //   variants: [
    //     {
    //       id: 8,
    //       color: "#669933",
    //       color_name: "Green",
    //       price: 68,
    //       pivot: {
    //         product_id: "94",
    //         component_id: "8",
    //       },
    //       size: [
    //         {
    //           id: 18,
    //           name: "Small",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "8",
    //             component_id: "18",
    //           },
    //         },
    //         {
    //           id: 19,
    //           name: "Large",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "8",
    //             component_id: "19",
    //           },
    //         },
    //       ],
    //     },
    //     {
    //       id: 6,
    //       color: "#3399cc",
    //       color_name: "Blue",
    //       price: 68,
    //       pivot: {
    //         product_id: "94",
    //         component_id: "6",
    //       },
    //       size: [
    //         {
    //           id: 15,
    //           name: "Medium",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "6",
    //             component_id: "15",
    //           },
    //         },
    //         {
    //           id: 16,
    //           name: "Large",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "6",
    //             component_id: "16",
    //           },
    //         },
    //         {
    //           id: 21,
    //           name: "Extra Large",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "6",
    //             component_id: "21",
    //           },
    //         },
    //       ],
    //     },
    //     {
    //       id: 5,
    //       color: "#f2719c",
    //       color_name: "Red",
    //       price: 65,
    //       pivot: {
    //         product_id: "94",
    //         component_id: "5",
    //       },
    //       size: [
    //         {
    //           id: 12,
    //           name: "Small",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "5",
    //             component_id: "12",
    //           },
    //         },
    //         {
    //           id: 14,
    //           name: "Medium",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "5",
    //             component_id: "14",
    //           },
    //         },
    //         {
    //           id: 13,
    //           name: "Large",
    //           slug: null,
    //           pivot: {
    //             components_variants_variant_id: "5",
    //             component_id: "13",
    //           },
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: 274,
    //   name: "DEWALT DCB205 20V",
    //   slug: "dewalt-dcb205-20v",
    //   short_desc:
    //     "Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, ",
    //   price: 69.94,
    //   sum: 200,
    //   sale_price: true,
    //   review: 2,
    //   ratings: 3,
    //   until: null,
    //   stock: 100,
    //   top: null,
    //   featured: null,
    //   new: true,
    //   author: null,
    //   sold: null,
    //   category: [
    //     {
    //       name: "Accessories",
    //       slug: "accessories",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "18",
    //       },
    //     },
    //     {
    //       name: "Power Tools",
    //       slug: "power-tools",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "74",
    //       },
    //     },
    //   ],
    //   brands: [
    //     {
    //       name: "Nike",
    //       slug: "nike",
    //       pivot: {
    //         product_id: "273",
    //         brand_id: "19",
    //       },
    //     },
    //   ],
    //   pictures: [
    //     {
    //       width: "800",
    //       height: "800",
    //       url: "./images/home/static-products/product2.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1415",
    //       },
    //     },
    //   ],
    //   sm_pictures: [
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product2.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //   ],
    //   variants: [],
    // },
    // {
    //   id: 275,
    //   name: "DEWALT DCB205 20V",
    //   slug: "dewalt-dcb205-20v",
    //   short_desc:
    //     "Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, ",
    //   price: 69.94,
    //   sum: 200,
    //   sale_price: true,
    //   review: 2,
    //   ratings: 3,
    //   until: null,
    //   stock: 100,
    //   top: null,
    //   featured: null,
    //   new: null,
    //   author: null,
    //   sold: null,
    //   category: [
    //     {
    //       name: "Accessories",
    //       slug: "accessories",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "18",
    //       },
    //     },
    //     {
    //       name: "Power Tools",
    //       slug: "power-tools",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "74",
    //       },
    //     },
    //   ],
    //   brands: [
    //     {
    //       name: "Nike",
    //       slug: "nike",
    //       pivot: {
    //         product_id: "273",
    //         brand_id: "19",
    //       },
    //     },
    //   ],
    //   pictures: [
    //     {
    //       width: "800",
    //       height: "800",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1415",
    //       },
    //     },
    //   ],
    //   sm_pictures: [
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //   ],
    //   variants: [],
    // },
    // {
    //   id: 276,
    //   name: "DEWALT DCB205 20V",
    //   slug: "dewalt-dcb205-20v",
    //   short_desc:
    //     "Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, ",
    //   price: 69.94,
    //   sum: 200,
    //   sale_price: null,
    //   review: 2,
    //   ratings: 3,
    //   until: null,
    //   stock: 100,
    //   top: null,
    //   featured: null,
    //   new: true,
    //   author: null,
    //   sold: null,
    //   category: [
    //     {
    //       name: "Accessories",
    //       slug: "accessories",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "18",
    //       },
    //     },
    //     {
    //       name: "Power Tools",
    //       slug: "power-tools",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "74",
    //       },
    //     },
    //   ],
    //   brands: [
    //     {
    //       name: "Nike",
    //       slug: "nike",
    //       pivot: {
    //         product_id: "273",
    //         brand_id: "19",
    //       },
    //     },
    //   ],
    //   pictures: [
    //     {
    //       width: "800",
    //       height: "800",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1415",
    //       },
    //     },
    //   ],
    //   sm_pictures: [
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //   ],
    //   variants: [],
    // },
    // {
    //   id: 277,
    //   name: "DEWALT DCB205 20V",
    //   slug: "dewalt-dcb205-20v",
    //   short_desc:
    //     "Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, ",
    //   price: 69.94,
    //   sum: 200,
    //   sale_price: null,
    //   review: 2,
    //   ratings: 3,
    //   until: null,
    //   stock: 100,
    //   top: null,
    //   featured: null,
    //   new: true,
    //   author: null,
    //   sold: null,
    //   category: [
    //     {
    //       name: "Accessories",
    //       slug: "accessories",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "18",
    //       },
    //     },
    //     {
    //       name: "Power Tools",
    //       slug: "power-tools",
    //       pivot: {
    //         product_id: "273",
    //         "product-category_id": "74",
    //       },
    //     },
    //   ],
    //   brands: [
    //     {
    //       name: "Nike",
    //       slug: "nike",
    //       pivot: {
    //         product_id: "273",
    //         brand_id: "19",
    //       },
    //     },
    //   ],
    //   pictures: [
    //     {
    //       width: "800",
    //       height: "800",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1415",
    //       },
    //     },
    //   ],
    //   sm_pictures: [
    //     {
    //       width: "300",
    //       height: "300",
    //       url: "./images/home/static-products/product3.png",
    //       pivot: {
    //         related_id: "273",
    //         upload_file_id: "1416",
    //       },
    //     },
    //   ],
    //   variants: [],
    // },
    {
      sku: "test_grouped_product",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1552,
              option_label: "Ja",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
            {
              option_id: 2131,
              option_label: "Apple Watch",
            },
            {
              option_id: 278,
              option_label: "DS Lite",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 57,
              option_label: "Blauw",
            },
            {
              option_id: 113,
              option_label: "Bruin",
            },
            {
              option_id: 110,
              option_label: "Geel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 164,
              option_label: "3+ meter",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "18.990000",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
      },
      url_key: "test-grouped-product",
      name: "Test Grouped Product",
      __typename: "GroupedProduct",
      canonical_url: "test-grouped-product",
      rating_summary: 0,
      review_count: 0,
      meta_title: null,
      meta_keyword: null,
      meta_description: "Test Grouped Product ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 15:01:47",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value:
            "3 maanden mits het onderdeel aantoonbaar deskundig geïnstalleerd is",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Service Pack",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "2 Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Ja",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek, Apple Watch, DS Lite",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "5 meter",
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur",
          value: "Blauw, Bruin, Geel",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "test_bundle",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 15,
              option_label: "Origineel",
            },
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "50.000000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
            {
              option_id: 59,
              option_label: "Zwart",
            },
          ],
        },
        {
          attribute_code: "tablet_model",
          attribute_id: 346,
          label: "tablet_model",
          values: [
            {
              option_id: 2320,
              option_label: "Apple iPad 12.9 (2022)",
            },
            {
              option_id: 2319,
              option_label: "Apple iPad 2022",
            },
            {
              option_id: 1947,
              option_label: "Apple iPad Air (2020)",
            },
            {
              option_id: 2159,
              option_label: "Apple iPad Air (2022)",
            },
            {
              option_id: 2093,
              option_label: "Apple iPad Mini 6 (2021)",
            },
            {
              option_id: 1717,
              option_label: "Apple iPad Pro 11",
            },
            {
              option_id: 1904,
              option_label: "Apple iPad Pro 11 (2020)",
            },
            {
              option_id: 2058,
              option_label: "Apple iPad Pro 11 (2021)",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2377,
              option_label: "Apple iPhone 15",
            },
            {
              option_id: 2376,
              option_label: "Apple iPhone 15 Plus",
            },
            {
              option_id: 2375,
              option_label: "Apple iPhone 15 Pro",
            },
            {
              option_id: 2374,
              option_label: "Apple iPhone 15 Pro Max",
            },
          ],
        },
      ],
      items: [
        {
          option_id: 1,
          title: "Stekker",
          position: 1,
        },
        {
          option_id: 2,
          title: "Oortjes",
          position: 2,
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
      },
      url_key: "test-bundle",
      name: "Test bundle",
      __typename: "BundleProduct",
      canonical_url: "test-bundle",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Test bundle",
      meta_keyword: null,
      meta_description: "Test bundle ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 14:50:38",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Origineel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "DOA garantie (defect bij ontvangst)",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "N.v.t.",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Dekitted",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 31.98,
          },
          final_price: {
            value: 31.98,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "gze8u",
      stock_qty: 0,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "19.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2313,
              option_label: "Google Pixel 7",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
      },
      url_key: "batterij-google-pixel-7-gze8u-4355mah",
      name: "Batterij Google Pixel 7 - GZE8U - 4355mAh",
      __typename: "SimpleProduct",
      canonical_url: "batterij-google-pixel-7-gze8u-4355mah",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Batterij Google Pixel 7 - GZE8U - 4355mAh",
      meta_keyword: null,
      meta_description:
        "Batterij voor de Google Pixel 7. Type GZE8U. Bekijk en bestel direct! ✓ Gratis verzending NL ✓ Voor 17.00 besteld vandaag verstuurd ✓ Veilige betaling",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 13:03:31",
      stock_status: "OUT_OF_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "6 maanden",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Bulk",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "3,8",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "ep-t1510e-c",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 15,
              option_label: "Origineel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 1669,
              option_label: "2A / 2000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1552,
              option_label: "Ja",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 394,
              option_label: "USB-C",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 170,
              option_label: "0 - 0,5 meter",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "12.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
            {
              option_id: 59,
              option_label: "Zwart",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
      },
      url_key: "samsung-lader-usb-c-15w-ep-t1510e",
      name: "Samsung lader USB-C 15W EP-T1510E",
      __typename: "ConfigurableProduct",
      canonical_url: "samsung-lader-usb-c-15w-ep-t1510e",
      rating_summary: 0,
      review_count: 0,
      meta_title: null,
      meta_keyword: null,
      meta_description: null,
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 10:27:38",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Origineel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "5-9 Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "2A / 2000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Ja",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "USB-C",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "0",
        },
        {
          attribute_code: "mobile_hoesje_typnr_fabr",
          attribute_id: 224,
          label: "Artikelnummer fabrikant",
          value: "EP-T1510NWEGEU, GP-PTU022HEAWQ",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [
        {
          label: "Kleur",
          position: 1,
          use_default: true,
          attribute_code: "mobile_hoesje_kleur",
          values: [
            {
              value_index: 60,
              label: "Wit",
            },
            {
              value_index: 59,
              label: "Zwart",
            },
          ],
        },
      ],
    },
    {
      sku: "ho-e91-wi",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "19.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
      },
      url_key: "hoco-e91-anti-lost-tracker-tag",
      name: "Hoco E91 anti-lost tracker tag",
      __typename: "SimpleProduct",
      canonical_url: "hoco-e91-anti-lost-tracker-tag",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Hoco E91 anti-lost Tracker Tag kopen?",
      meta_keyword: null,
      meta_description:
        "Hoco E91 anti-lost tracker tag Ontdek de Hoco E91 anti-lost Tracker Tag - Het ultieme smart tracking alternatief!\r\nBen je op zoek naar een betrouwbare en geavanceerde trackingoplossing die jouw kostbare bezittingen veilig houdt? Maak kennis met de Hoco E9",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 10:25:27",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Retail",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur",
          value: "Wit",
        },
        {
          attribute_code: "mobile_hoesje_typnr_fabr",
          attribute_id: 224,
          label: "Artikelnummer fabrikant",
          value: "E91",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "mtjy3zm",
      stock_qty: 2,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "18.990000",
            },
          ],
        },
        {
          attribute_code: "tablet_model",
          attribute_id: 346,
          label: "tablet_model",
          values: [
            {
              option_id: 2320,
              option_label: "Apple iPad 12.9 (2022)",
            },
            {
              option_id: 2319,
              option_label: "Apple iPad 2022",
            },
            {
              option_id: 1947,
              option_label: "Apple iPad Air (2020)",
            },
            {
              option_id: 2159,
              option_label: "Apple iPad Air (2022)",
            },
            {
              option_id: 2093,
              option_label: "Apple iPad Mini 6 (2021)",
            },
            {
              option_id: 1717,
              option_label: "Apple iPad Pro 11",
            },
            {
              option_id: 1904,
              option_label: "Apple iPad Pro 11 (2020)",
            },
            {
              option_id: 2058,
              option_label: "Apple iPad Pro 11 (2021)",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2377,
              option_label: "Apple iPhone 15",
            },
            {
              option_id: 2376,
              option_label: "Apple iPhone 15 Plus",
            },
            {
              option_id: 2375,
              option_label: "Apple iPhone 15 Pro",
            },
            {
              option_id: 2374,
              option_label: "Apple iPhone 15 Pro Max",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      },
      url_key: "apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister",
      name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
      __typename: "SimpleProduct",
      canonical_url: "apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister",
      rating_summary: 0,
      review_count: 0,
      meta_title:
        "Koptelefoon (USB-C) geschikt voor Apple iPhone 15 Series/iPad Pro Series",
      meta_keyword: null,
      meta_description:
        "Apple USB-C audio headset MTJY3ZM/A EarPods Blister Ontdek de Ultieme Audio-Ervaring met Originele Apple Oortjes voor iPhone 15 series en iPad Pro\r\nLaat je onderdompelen in onge&euml;venaarde geluidskwaliteit met de originele Apple oortjes, nu met een han",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-19 16:16:15",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Retail",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "test_grouped_product",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1552,
              option_label: "Ja",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
            {
              option_id: 2131,
              option_label: "Apple Watch",
            },
            {
              option_id: 278,
              option_label: "DS Lite",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 57,
              option_label: "Blauw",
            },
            {
              option_id: 113,
              option_label: "Bruin",
            },
            {
              option_id: 110,
              option_label: "Geel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 164,
              option_label: "3+ meter",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "18.990000",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
      },
      url_key: "test-grouped-product4",
      name: "Test Grouped Product",
      __typename: "GroupedProduct",
      canonical_url: "test-grouped-product",
      rating_summary: 0,
      review_count: 0,
      meta_title: null,
      meta_keyword: null,
      meta_description: "Test Grouped Product ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 15:01:47",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value:
            "3 maanden mits het onderdeel aantoonbaar deskundig geïnstalleerd is",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Service Pack",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "2 Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Ja",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek, Apple Watch, DS Lite",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "5 meter",
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur",
          value: "Blauw, Bruin, Geel",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "test_bundle",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 15,
              option_label: "Origineel",
            },
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "50.000000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
            {
              option_id: 59,
              option_label: "Zwart",
            },
          ],
        },
        {
          attribute_code: "tablet_model",
          attribute_id: 346,
          label: "tablet_model",
          values: [
            {
              option_id: 2320,
              option_label: "Apple iPad 12.9 (2022)",
            },
            {
              option_id: 2319,
              option_label: "Apple iPad 2022",
            },
            {
              option_id: 1947,
              option_label: "Apple iPad Air (2020)",
            },
            {
              option_id: 2159,
              option_label: "Apple iPad Air (2022)",
            },
            {
              option_id: 2093,
              option_label: "Apple iPad Mini 6 (2021)",
            },
            {
              option_id: 1717,
              option_label: "Apple iPad Pro 11",
            },
            {
              option_id: 1904,
              option_label: "Apple iPad Pro 11 (2020)",
            },
            {
              option_id: 2058,
              option_label: "Apple iPad Pro 11 (2021)",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2377,
              option_label: "Apple iPhone 15",
            },
            {
              option_id: 2376,
              option_label: "Apple iPhone 15 Plus",
            },
            {
              option_id: 2375,
              option_label: "Apple iPhone 15 Pro",
            },
            {
              option_id: 2374,
              option_label: "Apple iPhone 15 Pro Max",
            },
          ],
        },
      ],
      items: [
        {
          option_id: 1,
          title: "Stekker",
          position: 1,
        },
        {
          option_id: 2,
          title: "Oortjes",
          position: 2,
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
      },
      url_key: "test-bundle2",
      name: "Test bundle",
      __typename: "BundleProduct",
      canonical_url: "test-bundle",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Test bundle",
      meta_keyword: null,
      meta_description: "Test bundle ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 14:50:38",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Origineel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "DOA garantie (defect bij ontvangst)",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "N.v.t.",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Dekitted",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 31.98,
          },
          final_price: {
            value: 31.98,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "gze8u",
      stock_qty: 0,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "19.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2313,
              option_label: "Google Pixel 7",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/b/a/batterij-google-pixel-7-gze8u-4355mah-1.jpg",
      },
      url_key: "batterij-google-pixel-7-gze8u-4355mah2",
      name: "Batterij Google Pixel 7 - GZE8U - 4355mAh",
      __typename: "SimpleProduct",
      canonical_url: "batterij-google-pixel-7-gze8u-4355mah",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Batterij Google Pixel 7 - GZE8U - 4355mAh",
      meta_keyword: null,
      meta_description:
        "Batterij voor de Google Pixel 7. Type GZE8U. Bekijk en bestel direct! ✓ Gratis verzending NL ✓ Voor 17.00 besteld vandaag verstuurd ✓ Veilige betaling",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 13:03:31",
      stock_status: "OUT_OF_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "6 maanden",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Bulk",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "3,8",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "ep-t1510e-c",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 15,
              option_label: "Origineel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 1669,
              option_label: "2A / 2000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1552,
              option_label: "Ja",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 394,
              option_label: "USB-C",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 170,
              option_label: "0 - 0,5 meter",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "12.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
            {
              option_id: 59,
              option_label: "Zwart",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-6_1.png",
      },
      url_key: "samsung-lader-usb-c-15w-ep-t1510e2",
      name: "Samsung lader USB-C 15W EP-T1510E",
      __typename: "ConfigurableProduct",
      canonical_url: "samsung-lader-usb-c-15w-ep-t1510e",
      rating_summary: 0,
      review_count: 0,
      meta_title: null,
      meta_keyword: null,
      meta_description: null,
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 10:27:38",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Origineel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "5-9 Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "2A / 2000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Ja",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "USB-C",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "0",
        },
        {
          attribute_code: "mobile_hoesje_typnr_fabr",
          attribute_id: 224,
          label: "Artikelnummer fabrikant",
          value: "EP-T1510NWEGEU, GP-PTU022HEAWQ",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [
        {
          label: "Kleur",
          position: 1,
          use_default: true,
          attribute_code: "mobile_hoesje_kleur",
          values: [
            {
              value_index: 60,
              label: "Wit",
            },
            {
              value_index: 59,
              label: "Zwart",
            },
          ],
        },
      ],
    },
    {
      sku: "ho-e91-wi",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "19.990000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/h/o/hoco-e91-anti-lost-tracker-tag-4.jpg",
      },
      url_key: "hoco-e91-anti-lost-tracker-tag2",
      name: "Hoco E91 anti-lost tracker tag",
      __typename: "SimpleProduct",
      canonical_url: "hoco-e91-anti-lost-tracker-tag",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Hoco E91 anti-lost Tracker Tag kopen?",
      meta_keyword: null,
      meta_description:
        "Hoco E91 anti-lost tracker tag Ontdek de Hoco E91 anti-lost Tracker Tag - Het ultieme smart tracking alternatief!\r\nBen je op zoek naar een betrouwbare en geavanceerde trackingoplossing die jouw kostbare bezittingen veilig houdt? Maak kennis met de Hoco E9",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 10:25:27",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Retail",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur",
          value: "Wit",
        },
        {
          attribute_code: "mobile_hoesje_typnr_fabr",
          attribute_id: 224,
          label: "Artikelnummer fabrikant",
          value: "E91",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 19.99,
          },
          final_price: {
            value: 19.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
    {
      sku: "mtjy3zm",
      stock_qty: 2,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "18.990000",
            },
          ],
        },
        {
          attribute_code: "tablet_model",
          attribute_id: 346,
          label: "tablet_model",
          values: [
            {
              option_id: 2320,
              option_label: "Apple iPad 12.9 (2022)",
            },
            {
              option_id: 2319,
              option_label: "Apple iPad 2022",
            },
            {
              option_id: 1947,
              option_label: "Apple iPad Air (2020)",
            },
            {
              option_id: 2159,
              option_label: "Apple iPad Air (2022)",
            },
            {
              option_id: 2093,
              option_label: "Apple iPad Mini 6 (2021)",
            },
            {
              option_id: 1717,
              option_label: "Apple iPad Pro 11",
            },
            {
              option_id: 1904,
              option_label: "Apple iPad Pro 11 (2020)",
            },
            {
              option_id: 2058,
              option_label: "Apple iPad Pro 11 (2021)",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2377,
              option_label: "Apple iPhone 15",
            },
            {
              option_id: 2376,
              option_label: "Apple iPhone 15 Plus",
            },
            {
              option_id: 2375,
              option_label: "Apple iPhone 15 Pro",
            },
            {
              option_id: 2374,
              option_label: "Apple iPhone 15 Pro Max",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      },
      url_key: "apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister2",
      name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
      __typename: "SimpleProduct",
      canonical_url: "apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister",
      rating_summary: 0,
      review_count: 0,
      meta_title:
        "Koptelefoon (USB-C) geschikt voor Apple iPhone 15 Series/iPad Pro Series",
      meta_keyword: null,
      meta_description:
        "Apple USB-C audio headset MTJY3ZM/A EarPods Blister Ontdek de Ultieme Audio-Ervaring met Originele Apple Oortjes voor iPhone 15 series en iPad Pro\r\nLaat je onderdompelen in onge&euml;venaarde geluidskwaliteit met de originele Apple oortjes, nu met een han",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-19 16:16:15",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "1 jaar",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Retail",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
      configurable_options: [],
    },
  ],
  blogs: [
    {
      id: 1,
      author: {
        name: "John Doe",
        author_url: "",
      },
      comments: "3",
      short_filtered_content:
        "<p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. Lorem ipsum dolor sit amet</p>",
      creation_time: "2020-05-28",
      slug: "sed-adipiscing-uymornare.",
      title: "Sed adipiscing uymornare.",
      type: "image",
      blog_categories: [
        {
          name: "Fashion",
          slug: "fashion",
          pivot: {
            blog_id: "132",
            "blog-category_id": "5",
          },
        },
      ],
      image: [
        {
          width: "375",
          height: "250",
          url:
            "@/esf_weert_mobilesupplies/assets/static/images/home/blogs/blog1.png",
          pivot: {
            related_id: "132",
            upload_file_id: "520",
          },
        },
      ],
    },
    {
      id: 2,
      author: {
        name: "John Doe",
        author_url: "",
      },
      comments: "3",
      short_filtered_content:
        "<p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. Lorem ipsum dolor sit amet</p>",
      creation_time: "2020-05-28",
      slug: "sed-adipiscing-uymornare.",
      title: "Sed adipiscing uymornare.",
      type: "image",
      blog_categories: [
        {
          name: "Fashion",
          slug: "fashion",
          pivot: {
            blog_id: "132",
            "blog-category_id": "5",
          },
        },
      ],
      image: [
        {
          width: "375",
          height: "250",
          url:
            "@/esf_weert_mobilesupplies/assets/static/images/home/blogs/blog1.png",
          pivot: {
            related_id: "132",
            upload_file_id: "520",
          },
        },
      ],
    },
    {
      id: 3,
      author: {
        name: "John Doe",
        author_url: "",
      },
      comments: "3",
      short_filtered_content:
        "<p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. Lorem ipsum dolor sit amet</p>",
      creation_time: "2020-05-28",
      slug: "sed-adipiscing-uymornare.",
      title: "Sed adipiscing uymornare.",
      type: "image",
      blog_categories: [
        {
          name: "Fashion",
          slug: "fashion",
          pivot: {
            blog_id: "132",
            "blog-category_id": "5",
          },
        },
      ],
      image: [
        {
          width: "375",
          height: "250",
          url:
            "@/esf_weert_mobilesupplies/assets/static/images/home/blogs/blog1.png",
          pivot: {
            related_id: "132",
            upload_file_id: "520",
          },
        },
      ],
    },
    {
      id: 4,
      author: {
        name: "John Doe",
        author_url: "",
      },
      comments: "3",
      short_filtered_content:
        "<p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. Lorem ipsum dolor sit amet</p>",
      creation_time: "2020-05-28",
      slug: "sed-adipiscing-uymornare.",
      title: "Sed adipiscing uymornare.",
      type: "image",
      blog_categories: [
        {
          name: "Fashion",
          slug: "fashion",
          pivot: {
            blog_id: "132",
            "blog-category_id": "5",
          },
        },
      ],
      image: [
        {
          width: "375",
          height: "250",
          url:
            "@/esf_weert_mobilesupplies/assets/static/images/home/blogs/blog1.png",
          pivot: {
            related_id: "132",
            upload_file_id: "520",
          },
        },
      ],
    },
    {
      id: 5,
      author: {
        name: "John Doe",
        author_url: "",
      },
      comments: "3",
      short_filtered_content:
        "<p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero augue. Lorem ipsum dolor sit amet</p>",
      creation_time: "2020-05-28",
      slug: "sed-adipiscing-uymornare.",
      title: "Sed adipiscing uymornare.",
      type: "image",
      blog_categories: [
        {
          name: "Fashion",
          slug: "fashion",
          pivot: {
            blog_id: "132",
            "blog-category_id": "5",
          },
        },
      ],
      image: [
        {
          width: "375",
          height: "250",
          url:
            "@/esf_weert_mobilesupplies/assets/static/images/home/blogs/blog1.png",
          pivot: {
            related_id: "132",
            upload_file_id: "520",
          },
        },
      ],
    },
  ],
  blogCategories: [
    {
      name: "Furniture",
      slug: "furniture",
      count: 8,
    },
    {
      name: "Coffee & Tables",
      slug: "coffee-and-tables",
      count: 1,
    },
    {
      name: "Lighting",
      slug: "lighting",
      count: 3,
    },
    {
      name: "Decoration",
      slug: "decoration",
      count: 5,
    },
    {
      name: "Electronics",
      slug: "electronics",
      count: 1,
    },
    {
      name: "Beds",
      slug: "beds",
      count: 2,
    },
    {
      name: "Armchairs & Chaises",
      slug: "armchairs-and-chaises",
      count: 3,
    },
    {
      name: "Sofas & Sleeper Sofas",
      slug: "sofas-and-sleeper-sofas",
      count: 1,
    },
  ],
  wishlistProducts: [
    {
      sku: "test_grouped_product",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1552,
              option_label: "Ja",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
            {
              option_id: 2131,
              option_label: "Apple Watch",
            },
            {
              option_id: 278,
              option_label: "DS Lite",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 57,
              option_label: "Blauw",
            },
            {
              option_id: 113,
              option_label: "Bruin",
            },
            {
              option_id: 110,
              option_label: "Geel",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 164,
              option_label: "3+ meter",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "18.990000",
            },
          ],
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/b/a/batterij-google-pixel-7-gze8u-4355mah-2_1.jpg",
      },
      url_key: "test-grouped-product8",
      name: "Test Grouped Product",
      __typename: "GroupedProduct",
      canonical_url: "test-grouped-product",
      rating_summary: 0,
      review_count: 0,
      meta_title: null,
      meta_keyword: null,
      meta_description: "Test Grouped Product ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 15:01:47",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Compatibel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value:
            "3 maanden mits het onderdeel aantoonbaar deskundig geïnstalleerd is",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "Carry-in-garantie",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Service Pack",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "2 Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Ja",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek, Apple Watch, DS Lite",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "5 meter",
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur",
          value: "Blauw, Bruin, Geel",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 18.99,
          },
          final_price: {
            value: 18.99,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
    },
    {
      sku: "test_bundle",
      stock_qty: 20,
      DeliveryTime: {
        color: "",
        icon: "",
        long: "",
        short: "",
      },
      labels: null,
      sales_data: {
        last_order_date: null,
        orders: 0,
        qty_ordered: 0,
      },
      filter_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          values: [
            {
              option_id: 15,
              option_label: "Origineel",
            },
            {
              option_id: 14,
              option_label: "Compatibel",
            },
          ],
        },
        {
          attribute_code: "price",
          attribute_id: 75,
          label: "Price",
          values: [
            {
              option_id: null,
              option_label: "50.000000",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          values: [
            {
              option_id: 181,
              option_label: "1A / 1000mAh",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          values: [
            {
              option_id: 1551,
              option_label: "Nee",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type connector/aansluiting",
          values: [
            {
              option_id: 299,
              option_label: "Apparaat specifiek",
            },
          ],
        },
        {
          attribute_code: "mobile_laders_kabellengte",
          attribute_id: 255,
          label: "Kabellengte filter",
          values: [
            {
              option_id: 168,
              option_label: "1 - 1,5 meter",
            },
          ],
        },
        {
          attribute_code: "mobile_hoesje_kleur",
          attribute_id: 222,
          label: "Kleur hoesje",
          values: [
            {
              option_id: 60,
              option_label: "Wit",
            },
            {
              option_id: 59,
              option_label: "Zwart",
            },
          ],
        },
        {
          attribute_code: "tablet_model",
          attribute_id: 346,
          label: "tablet_model",
          values: [
            {
              option_id: 2320,
              option_label: "Apple iPad 12.9 (2022)",
            },
            {
              option_id: 2319,
              option_label: "Apple iPad 2022",
            },
            {
              option_id: 1947,
              option_label: "Apple iPad Air (2020)",
            },
            {
              option_id: 2159,
              option_label: "Apple iPad Air (2022)",
            },
            {
              option_id: 2093,
              option_label: "Apple iPad Mini 6 (2021)",
            },
            {
              option_id: 1717,
              option_label: "Apple iPad Pro 11",
            },
            {
              option_id: 1904,
              option_label: "Apple iPad Pro 11 (2020)",
            },
            {
              option_id: 2058,
              option_label: "Apple iPad Pro 11 (2021)",
            },
          ],
        },
        {
          attribute_code: "mobile_model",
          attribute_id: 342,
          label: "mobile_model",
          values: [
            {
              option_id: 2377,
              option_label: "Apple iPhone 15",
            },
            {
              option_id: 2376,
              option_label: "Apple iPhone 15 Plus",
            },
            {
              option_id: 2375,
              option_label: "Apple iPhone 15 Pro",
            },
            {
              option_id: 2374,
              option_label: "Apple iPhone 15 Pro Max",
            },
          ],
        },
      ],
      items: [
        {
          option_id: 1,
          title: "Stekker",
          position: 1,
        },
        {
          option_id: 2,
          title: "Oortjes",
          position: 2,
        },
      ],
      thumbnail: {
        thumbnail:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        small:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        medium:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
        x_large:
          "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/s/a/samsung-lader-usb-c-15w-ep-t1510ewe-wit-2_2.png",
      },
      url_key: "test-bundle3",
      name: "Test bundle",
      __typename: "BundleProduct",
      canonical_url: "test-bundle",
      rating_summary: 0,
      review_count: 0,
      meta_title: "Test bundle",
      meta_keyword: null,
      meta_description: "Test bundle ",
      new_from_date: null,
      new_to_date: null,
      created_at: "2024-01-23 14:50:38",
      stock_status: "IN_STOCK",
      information_attributes: [
        {
          attribute_code: "origineel_product_ja_nee",
          attribute_id: 194,
          label: "Origineel of compatibel",
          value: "Origineel",
        },
        {
          attribute_code: "garantieperiode",
          attribute_id: 185,
          label: "Garantieperiode",
          value: "DOA garantie (defect bij ontvangst)",
        },
        {
          attribute_code: "garantietype",
          attribute_id: 186,
          label: "Garantie vorm",
          value: "N.v.t.",
        },
        {
          attribute_code: "verpakking",
          attribute_id: 188,
          label: "Verpakking",
          value: "Dekitted",
        },
        {
          attribute_code: "digicam_voltage",
          attribute_id: 193,
          label: "Voltage (V)",
          value: "Volt",
        },
        {
          attribute_code: "mobile_laders_output",
          attribute_id: 258,
          label: "Output stroom",
          value: "1A / 1000mAh",
        },
        {
          attribute_code: "mobile_laders_fch",
          attribute_id: 362,
          label: "Geschikt voor Fast Charge",
          value: "Nee",
        },
        {
          attribute_code: "mobile_laders_connectors",
          attribute_id: 257,
          label: "Type aansluiting",
          value: "Apparaat specifiek",
        },
        {
          attribute_code: "mobile_laders_kabel_exact",
          attribute_id: 256,
          label: "Kabellengte",
          value: "1 meter",
        },
      ],
      manufacturer_price: {
        price: {
          currency: null,
          value: null,
        },
        discount: null,
      },
      price_range: {
        minimum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 12.99,
          },
          final_price: {
            value: 12.99,
          },
        },
        maximum_price: {
          discount: {
            amount_off: 0,
            percent_off: 0,
          },
          regular_price: {
            value: 31.98,
          },
          final_price: {
            value: 31.98,
          },
        },
      },
      special_from_date: null,
      special_to_date: null,
      special_price: null,
    },
  ],
};

export const orderData = [
  {
    order_no: "2313213124",
    date: "15-05-2021",
    sent_to: "John Doe",
    total: "€ 200.00",
    status: "Delivered",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
    price_range: {
      minimum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
      maximum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
    },
    stock_status: "IN_STOCK",
  },
  {
    order_no: "2313213124",
    date: "15-05-2021",
    sent_to: "John Doe",
    total: "€ 200.00",
    status: "Delivered",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
    price_range: {
      minimum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
      maximum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
    },
    stock_status: "IN_STOCK",
  },
  {
    order_no: "2313213124",
    date: "15-05-2021",
    sent_to: "John Doe",
    total: "€ 200.00",
    status: "Delivered",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
    price_range: {
      minimum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
      maximum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
    },
    stock_status: "IN_STOCK",
  },
  {
    order_no: "2313213124",
    date: "15-05-2021",
    sent_to: "John Doe",
    total: "€ 200.00",
    status: "Cancelled",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
    price_range: {
      minimum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
      maximum_price: {
        discount: {
          amount_off: 0,
          percent_off: 0,
        },
        regular_price: {
          value: 18.99,
        },
        final_price: {
          value: 18.99,
        },
      },
    },
    stock_status: "IN_STOCK",
  },
];

export const reviewsData = [
  {
    date: "15-05-2021",
    product_name: "lorem ipsum dolor sit amet",
    rating: 5,
    review: "lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
  },
  {
    date: "15-05-2021",
    product_name: "lorem ipsum dolor sit amet",
    rating: 5,
    review: "lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
  },
  {
    date: "15-05-2021",
    product_name: "lorem ipsum dolor sit amet",
    rating: 5,
    review: "lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    thumbnail: {
      thumbnail:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/bbdc28d6f3d30f2955a8859e6f5380a8/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      small:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/62474b41e96c7e23a51329d1b3f6f815/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      medium:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/320ff36b6166cf376c84f5f3e5f453d6/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/ee12110474fe44482c50ab08d4f138c2/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
      x_large:
        "https://m2.mobilesupplies.test.e-bricks.cloud/media/catalog/product/cache/e42f1bd87a99108e6696bdfc77657cec/a/p/apple-usb-c-audio-headset-mtjy3zm-a-earpods-blister_2_1.png",
    },
    name: "Apple USB-C audio headset MTJY3ZM/A EarPods Blister",
  },
];
